body {
    background-color: #ededed;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    margin: 0;
    padding: 0;
}

a {
    cursor: pointer;
}

@media print {
    body {
        background-color: #fff;
    }

    @page {
        size: auto;
        margin: 0;
    }
}
